import Service from "./Service";

class EdrColleageService extends Service {
  constructor() {
    super();
  }
  async get(pageParam) {
    return this.callApiGet(`/edrColleage${pageParam}`);
  }
  async findAllProductionSite() {
    return this.callApiGet(`/edrColleageOnlyProduction`);
  }
  async edrColleageByCode(collegeCode) {
    return this.callApiGet(`/edrColleageByCode?collegeCode=${collegeCode}`);
  }
  async create(postData) {
    return this.callApiPost(`/edrColleage`, postData);
  }
  async update(postData) {
    return this.callApiPut(`/edrColleage`, postData);
  }
  async delete(id) {
    return this.callApiDelete(`/edrColleage?_ids=${id}`);
  }
  // cmd
  async excuteCmd(postData, collegData) {
    return this.callApiCmdForEdr(`/excuteCmd`, postData, collegData);
  }
}
export default EdrColleageService;
