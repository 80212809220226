<template>
  <v-container
    fluid
    class="fill-height"
  >

    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="5"
        lg="4"
        class="ma-auto"
      >
        <v-card
          v-if="!loading && collegeData"
          class="px-8 pt-6 pb-12"
          flat
        >
          <v-card-title
            v-if="collegeData"
            class="text-center font-weight-black"
          >
            <v-icon class="mr-2">mdi-qrcode</v-icon> {{collegeData.name_th}}
          </v-card-title>
          <v-card-subtitle class="mb-1 mt-1 subtitle-2 grey--text text--lighten-1">{{$t('nav.generateQrCheckinOut')}}</v-card-subtitle>

          <v-card-text>

            <qr-code
              v-if="getCode.length>0"
              bg-color="#fff"
              color="#000"
              :text="getCode"
              :size="300"
              error-level="L"
            >
            </qr-code>

            <div class="mt-5">
              <a
                class="black--text text-decoration-none"
                :href="`https://cdn.appedr.com/qr_checkin/qr_${collegeCode}.pdf`"
                download
              >
                <v-icon>mdi-file-pdf</v-icon>Download
              </a>
            </div>

          </v-card-text>
        </v-card>
        <template v-else-if="!loading && !collegeData">
          <wee-error :image="true" />
        </template>
      </v-col>
    </v-row>

    <base-wee-loader v-model="loading" />
    <wee-toast ref="weeToastRef"></wee-toast>
  </v-container>
</template>

<script>
import useBase from "@/composition/UseBase";
import { PoroductionMode } from "@/plugins/config";
import {
  onBeforeUnmount,
  onMounted,
  ref,
  computed
} from "@vue/composition-api";
import EdrColleageService from "@/api/EdrColleageService";
export default {
  components: {
    WeeToast: () => import("@/components/WeeToast"),
    QrCode: () => import("vue-qrcode-component"),
    WeeError: () => import("@/components/WeeError")
  },
  setup(props, { refs, root }) {
    const collegeCode = ref(null);
    const collegeData = ref(null);
    const loading = ref(true);
    const edrColleageService = new EdrColleageService();
    const { toast } = useBase(refs, root);
    const { $route } = root;
    onMounted(async () => {
      collegeCode.value = $route.params.collegeCode;
      await loadColegeData();
    });
    const loadColegeData = async () => {
      const { response, error } = await edrColleageService.edrColleageByCode(
        collegeCode.value
      );
      loading.value = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response && response.entity) {
        collegeData.value = response.entity;
      }
      return new Promise(resove => {
        resove(true);
      });
    };
    const getCode = computed(() => {
      if (collegeData.value) {
        // return `https://center.appedr.com/check-in/${collegeData.value.college_code}/checkin`;
        return PoroductionMode
          ? `https://center.appedr.com/check-in/${collegeData.value.college_code}/checkin`
          : `http://192.168.7.200:8080/check-in/${collegeData.value.college_code}/checkin`;
      }
      return "";
    });
    onBeforeUnmount(() => {});

    return {
      loading,
      collegeData,
      getCode,
      collegeCode
    };
  }
};
</script>
